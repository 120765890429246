@mixin default-picture {
  .default-picture {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      // Height can be overwritten by other styles for particular component.
      // IE doesn't support 'object-fit' property, so always use height: auto
      // in case of IE browser to avoid stretched images.
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        height: auto !important;
      }
    }
  }
}
