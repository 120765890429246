@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/default-picture';

// Component name variable for dynamic selector names
$component-name: 'bb-cta-primary';

// Root selector declared only once
.#{$component-name} {
  &--button {
    display: block;
    margin: auto;
    width: max-content;
  }

  &__icon-list {
    margin-bottom: 32px;

    &-item {
      margin-bottom: 12px;
      padding-left: 32px;

      &::before {
        background-image: url('/static/icons/teal-arrow.svg');
        background-color: transparent;
        transform: none;
        height: 20px;
        width: 20px;
        top: 4px;
      }

      > * {
        margin-bottom: 0;
      }
    }
  }

  // Overlay styles
  &__overlay {
    padding: 24px 20px;
  }

  // Title styles
  &__title {
    text-align: center;
    margin-bottom: 24px;
  }

  // Exceptions for green variant
  &--color-green {
    background: red;
    .#{$component-name}__overlay {
      text-align: left;

      .#{$component-name}__icon-list-item::before,
      li.icon-list::before {
        background-image: url('/static/icons/teal-tick.svg');
      }

      [data-component^="button-"] {
        margin: 0;
      }
    }
  }

  // Global styles
  :global {
    @include default-picture();

    .default-picture img {
      // Non-responsive styles handled below
    }
  }
}

/* ========================= */
/*       Media Queries       */
/* ========================= */

/* Medium and up */
@include media-breakpoint-up(md) {
  .#{$component-name} {
    min-height: 700px;

    &__overlay {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 40px;
      bottom: auto;
      width: 470px;
      background-color: $white;
    }

    &__title {
      text-align: left;
    }

    :global {
      .default-picture img {
        height: 700px;
        transition: transform 0.4s ease-in-out;
      }
    }
  }
}

/* Large and down */
@include media-breakpoint-down(lg) {
  .#{$component-name}--color-green {
    .#{$component-name}__overlay {
      [data-component^="button-"] {
        width: auto;
      }
    }
  }
}

/* Medium and down */
@include media-breakpoint-down(md) {
  .#{$component-name}--color-green {
    margin: 0;
    padding: 0;

    .#{$component-name}__overlay {
      position: absolute;
      padding: 40px 20px;
      width: 100%;
      background: linear-gradient(
                      to bottom,
                      rgba(255, 255, 255, 0),
                      rgba(255, 255, 255, 1) 50%
      );
      bottom: 0;
      left: 0;
    }
  }
}
